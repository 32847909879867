import React, { Component } from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'
import './App.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify, faApple, faSoundcloud, faXTwitter, faInstagram, faBluesky, faFacebook } from '@fortawesome/free-brands-svg-icons'
import HummingbirdCover from './img/LGBHummingbirdGoesSideways640.jpg'

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			spotify: 'https://open.spotify.com/artist/6K7U0Stm4W5N1O5EM7QN0i?si=N4KRaapLSQGTkAGN1uOoQw',
			apple: 'https://itunes.apple.com/us/artist/long-gone-blond/id432986969',
			soundcloud: 'https://soundcloud.com/long-gone-blond/',
			twitter: 'https://www.twitter.com/longgoneblond',
			bluesky: 'https://bsky.app/profile/longgoneblond.bsky.social',
			bodyBandcamp: 'https://longgoneblond.bandcamp.com/',
			instagram: 'https://www.instagram.com/longgoneblond',
			facebook: 'https://www.facebook.com/longgoneblond'
		}
		this.processLink = this.processLink.bind(this);
	}

	processLink = (dest) => {
		window.location = this.state[dest]
	}

	render() {
		return (
			<div className='App'>
				<Row>
					<Col sm='0' md='2' className='d-none d-md-block'>
					</Col>
					<Col sm='12' md='8' style={{ marginTop: '15px', opacity: '0.7' }}>
						<Row className='justify-content-center'>
							<div className='services'>
								<FontAwesomeIcon className='linkIcon' icon={faSpotify} size='4x' onClick={() => this.processLink('spotify')} />
								<FontAwesomeIcon className='linkIcon' icon={faApple} size='4x' onClick={() => this.processLink('apple')} />
								<FontAwesomeIcon className='linkIcon' icon={faSoundcloud} size='4x' onClick={() => this.processLink('soundcloud')} />
								<FontAwesomeIcon className='linkIcon' icon={faBluesky} size='4x' onClick={() => this.processLink('bluesky')} />
								<FontAwesomeIcon className='linkIcon' icon={faFacebook} size='4x' onClick={() => this.processLink('facebook')} />
								<FontAwesomeIcon className='linkIcon' icon={faInstagram} size='4x' onClick={() => this.processLink('instagram')} />
							</div>
						</Row>

					</Col>
					<Col sm='0' md='2' className='d-none d-md-block'>
					</Col>
				</Row>
				<Row>

					<Col>
						<Card className='feature-card'>
							<Card.Title className='feature-title glow'>JANUARY 24TH ON ALL PLATFORMS</Card.Title>
							<Card.Body className='feature-body'>
								<img src={HummingbirdCover} alt='The Hummingbird Goes Sideways Album Cover' style={{ maxWidth: '100%', maxHeight: '100%' }} />
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row>
					<div style={{ width: '10px', height: '100px', display: 'block' }}>

					</div>
				</Row>
			</div>
		)
	}
}

export default App
